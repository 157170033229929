import { Button, Stack } from '@mui/material';
import { Link, Route, useNavigate, useParams } from 'react-router-dom';
import AddIcon from '@mui/icons-material/Add';
import DialogOutletRoutes from '@components/Dialog/DialogOutletRoutes';
import  AddAccountModal from '@views/Administration/UserAccounts/components/AddAccountModal';
import { useCallback, React } from 'react';
import useFetch from '@hooks/useFetch';
import { FormattedMessage, useIntl } from 'react-intl';
import { WorkflowContributorAccess } from '@views/constants';
import withPermissions from '@components/withTenantPermissions';
import { contributorCreatePermission } from '@components/WorkflowGeneric/WorkflowContributors/WorkflowContributorEditor';
import { useSnackbar } from 'notistack';

export default function ContractExternalContributor({
  workflowStepId,
  refresh,
  workFlowAccess,
}) {
  const intl = useIntl();
  const navigate = useNavigate();
  const navigateToWorkflow = () => navigate(`.`);
  const { enqueueSnackbar } = useSnackbar();
  const { contractId } = useParams();
  const { doFetch: doPost } = useFetch({
    method: 'POST',
    onSuccess: () => {
      enqueueSnackbar(
        intl.formatMessage({ id: 'Workflow.Contributor.Modal.Add.Success' }),
        {
          variant: 'success',
          autoHideDuration: 2000,
          anchorOrigin: {
            vertical: 'top',
            horizontal: 'right',
          },
        }
      );

      navigateToWorkflow();
      if (refresh) {
        refresh();
      }
    },
    onError: response => {
      const dataError = 'data' in response ? response.data : null;
      const errors = dataError?.Errors ?? dataError?.errors;
      if (errors) {
        const listError = Object.values(errors)
          .flat()
          .map(key =>
            intl.formatMessage({
              id: `Errors.${typeof key === 'object' ? key?.message : key}`,
            })
          );
        enqueueSnackbar(listError.join(', '), {
          variant: 'error',
          autoHideDuration: 2000,
          anchorOrigin: {
            vertical: 'top',
            horizontal: 'right',
          },
        });
      } else {
        enqueueSnackbar(intl.formatMessage({ id: 'Errors.ErrorOccured' }), {
          variant: 'error',
          autoHideDuration: 2000,
          anchorOrigin: {
            vertical: 'top',
            horizontal: 'right',
          },
        });
      }
    },
  });

  const handleCreateNewAccount = useCallback(
    values => {
      const { groupIds, roleIds, ...finalValues } = values;
      doPost({
        url: `contracts/${contractId}/workflow/steps/${workflowStepId}/contributors`,
        body: {
          ...finalValues,
        },
      });
    },
    [contractId, workflowStepId, doPost]
  );

  const ButtonWithPermission = withPermissions(Button);

  return (
    <>
      <Stack sx={{ py: '5px' }} gap={2}>
        <ButtonWithPermission
          permissions={contributorCreatePermission}
          component={Link}
          to="contributor-modals/create"
          sx={{ textDecoration: 'none', textTransform: 'unset' }}
          variant="text"
          startIcon={<AddIcon />}
        >
          Ajouter un contributeur
        </ButtonWithPermission>
      </Stack>
      <DialogOutletRoutes
        path="contributor-modals/*"
        onClose={navigateToWorkflow}
      >
        <Route
          path="create"
          element={
            <AddAccountModal
              permissions={contributorCreatePermission}
              onSubmit={handleCreateNewAccount}
              hideEmptyList="true"
              showWorkflowAccess="true"
              initWorkflowAccessValue={
                workFlowAccess != null
                  ? WorkflowContributorAccess[
                      workFlowAccess[0]?.replace('-r', 'R')
                    ]
                  : 0
              }
              disabledWorkflowAccess
              title={
                <FormattedMessage id="Workflow.Contributor.Modal.Add.Title" />
              }
            />
          }
        />
      </DialogOutletRoutes>
    </>
  );
}
