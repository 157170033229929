import { styled } from '@mui/material/styles';
import MuiDrawer from '@mui/material/Drawer';
import IconButton from '@mui/material/IconButton';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import { FormattedMessage } from 'react-intl';
import Stack from '@mui/material/Stack';
import useDisclosure from '@hooks/useDisclosure';
import { Link } from 'react-router-dom';
import useMediaQuery from '@mui/material/useMediaQuery';
import React from 'react';
import { apiStatusTexts } from '@components/Chips/DocumentChip';
import ErrorBoundary from '@components/ErrorBoundary/ErrorBoundary';
import { useRecoilValue } from 'recoil';
import { tenantRolesIsInternalSelector } from '@recoil/tenant-token';
import useDelegationColor from '@hooks/useDelegationColor';
import WorkflowStepper from './WorkflowStepper';

export const drawerWidth = 300;

const openedMixin = theme => ({
  width: drawerWidth,
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: 'hidden',
});

const closedMixin = theme => ({
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: 'hidden',
  width: `calc(${theme.spacing(9)} + 1px)`,
});

const DrawerHeader = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-end',
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
}));

const Drawer = styled(MuiDrawer, {
  shouldForwardProp: prop => prop !== 'open',
})(({ theme, open }) => ({
  flexShrink: 1,
  whiteSpace: 'pre-wrap',
  boxSizing: 'border-box',
  '& .MuiDrawer-paper': {
    boxSizing: 'border-box',
    ...(open ? openedMixin(theme) : closedMixin(theme)),
  },
  ...(open ? openedMixin(theme) : closedMixin(theme)),
}));

export default function WorkflowStepperWrapper({
  workflow,
  status,
  workFlowAccess = null,
  hideEditWorkflowButton = false,
  hideWorkflowInfo = false,
  refresh = () => {},
}) {
  const smallScreen = useMediaQuery('(min-width:1400px)', { noSsr: true });
  const { isOpen, onToggle } = useDisclosure(smallScreen);
  const isInternal = useRecoilValue(tenantRolesIsInternalSelector);
  const IsEditButtonHidden =
    status === apiStatusTexts.finished ||
    status === apiStatusTexts.signed ||
    !isInternal ||
    hideEditWorkflowButton;

  const color = useDelegationColor('');

  return (
    <Drawer
      variant="permanent"
      sx={{
        flexGrow: 1,
        '& .MuiDrawer-root': {
          position: 'relative',
        },
        '& .MuiPaper-root': {
          position: 'relative',
        },
      }}
      PaperProps={{
        sx: {
          bgcolor: color ? `${color}.main` : undefined,
        },
      }}
      open={isOpen}
    >
      <DrawerHeader sx={{ justifyContent: 'space-between', px: 2 }}>
        {isOpen ? (
          <Typography
            sx={{ fontWeight: 700, color: 'grey.700', fontSize: '16px' }}
          >
            Workflow
          </Typography>
        ) : null}
        <IconButton onClick={onToggle}>
          {isOpen ? <ChevronLeftIcon /> : <ChevronRightIcon />}
        </IconButton>
      </DrawerHeader>
      <Stack alignItems="center" justifyContent="center">
        {isOpen && !IsEditButtonHidden && (
          <Button
            component={Link}
            to="workflow"
            sx={{ textDecoration: 'none' }}
            variant="outlined"
          >
            <FormattedMessage
              id="Reader.Stepper.Edit.Label"
              defaultMessage="Edit"
            />
          </Button>
        )}
        <ErrorBoundary>
          <WorkflowStepper
            isOpen={isOpen}
            workflow={workflow}
            workFlowAccess={workFlowAccess}
            refresh={refresh}
            hideWorkflowInfo={hideWorkflowInfo}
          />
        </ErrorBoundary>
      </Stack>
    </Drawer>
  );
}
