import { useAppContext } from '@contexts/AppContext';
import { compute } from '@utils/username-helpers';
import { useCallback } from 'react';
import { useIntl } from 'react-intl';

export const GUID_EMPTY = '00000000-0000-0000-0000-000000000000';

export const label = (
  { type, entity },
  formatMessage,
  { showEmail = false, showExternalIds = true } = {}
) => {
  if (type === 1 && entity?.id === GUID_EMPTY) {
    return formatMessage({ id: 'Workflow.Step.Author.Label' });
  }
  if (type === 1) {
    if (showEmail) {
      const firstName = entity?.firstName || '';
      const lastName = entity?.lastName || '';
      const fullName = `${firstName} ${lastName}`.trim();
      const result =
        fullName && entity?.email ? `${fullName} (${entity.email})` : fullName;
      return result || entity?.id;
    }

    return (
      compute(
        entity?.email,
        entity?.firstName,
        entity?.lastName,
        showExternalIds ? entity?.externalId : ''
      ).value || entity?.id
    );
  }
  if (type === 2) {
    return entity?.name || entity?.id;
  }
  return '';
};

export const useLabel = () => {
  const appContext = useAppContext();
  const { formatMessage } = useIntl();
  const showEmail = appContext.contract.workflow.showEmail ?? false;
  const showExternalIds = appContext.contract.workflow.showExternalIds ?? true;
  return useCallback(
    entity =>
      label(entity, formatMessage, {
        showEmail,
        showExternalIds,
      }),
    [formatMessage, showEmail, showExternalIds]
  );
};
