import React from 'react';
import { FormattedMessage } from 'react-intl';
import { Field } from 'formik';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import InputAdornment from '@mui/material/InputAdornment';
import { valueFromPath } from '@utils/json-path';

function getDirectoryName(files) {
  if (!files) return '';
  const file = files?.find(f => !!f.webkitRelativePath);
  if (!file) return '';
  let folderName = file.webkitRelativePath.split('/').at(0);
  folderName = folderName.split('\\').at(0);
  return folderName;
}

function getValueArray(value) {
  if (!value) return value;
  if (Array.isArray(value)) return value;
  if (value instanceof FileList) return Array.from(value);
  return [value];
}

function MuiFileField({
  field,
  form: { touched, errors, setFieldValue, setFieldTouched },
  inputProps,
  accept,
  label,
  onChanged,
  multiple,
  directory,
  maxSize,
  ...props
}) {
  const { name } = field;
  const error = name.includes('.') ? valueFromPath(errors, name) : errors[name];
  const touch = name.includes('.')
    ? valueFromPath(touched, name)
    : touched[name];
  const hasError = Boolean(touch) && Boolean(error);

  const valueArray = getValueArray(field.value);

  const textValue = directory
    ? getDirectoryName(valueArray)
    : valueArray?.at(0)?.name ?? '';

  const hasMaxSize = maxSize !== null && maxSize !== undefined;

  return (
    <TextField
      fullWidth
      margin="normal"
      autoComplete="off"
      InputProps={{
        sx: { borderTopRightRadius: 0, borderBottomRightRadius: 0 },
        readOnly: true,
        endAdornment: (
          <InputAdornment position="end">
            <Button
              disableElevation
              sx={{ borderRadius: 0 }}
              variant="contained"
              component="label"
            >
              <FormattedMessage id="Verbs.Choose" defaultMessage="Choose" />
              <input
                type="file"
                hidden
                multiple={inputProps?.multiple ?? multiple}
                webkitdirectory={
                  inputProps?.webkitdirectory ?? (directory ? '' : undefined)
                }
                {...inputProps}
                accept={accept?.join(',') ?? inputProps?.accept}
                onChange={event => {
                  setFieldTouched(name, true);
                  if (multiple || directory) {
                    const files = event.currentTarget.files
                      ? Array.from(event.currentTarget.files)
                      : null;

                    setFieldValue(name, files);
                    onChanged?.(event, files);
                  } else {
                    const file = event.currentTarget.files?.[0];
                    setFieldValue(name, file);
                    onChanged?.(event, file);
                  }
                }}
              />
            </Button>
          </InputAdornment>
        ),
      }}
      {...props}
      label={
        !hasMaxSize ? (
          label
        ) : (
          <>
            {label} ({maxSize} maximum)
          </>
        )
      }
      value={textValue}
      onChange={() => {}}
      onBlur={() => {}}
      error={hasError}
      helperText={
        hasError && (
          <FormattedMessage
            id={error}
            values={{
              label: label ?? '',
              value: textValue,
              maxSize,
            }}
          />
        )
      }
      sx={{ flexGrow: 1 }}
    />
  );
}

function FormikFileField(props) {
  return <Field component={MuiFileField} {...props} />;
}

export default FormikFileField;
