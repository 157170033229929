import Typography from '@mui/material/Typography';
import { useOutletContext, useParams } from 'react-router-dom';
import { FormattedMessage, useIntl } from 'react-intl';
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import DialogWithClose from '@components/Dialog/DialogWithClose';
import useFetch from '@hooks/useFetch';
import { useSnackbar } from 'notistack';

export default function DeleteAnnexModal({
  documentId,
  onSuccess,
}) {
  const { close } = useOutletContext();
  const { enqueueSnackbar } = useSnackbar();
  const { annexId } = useParams();
  const intl = useIntl();

  const deleteFetch = useFetch({
    url: `documents/${documentId}/annexes/${annexId}`,
    method: 'DELETE',
    onSuccess: () => {
      enqueueSnackbar(
        intl.formatMessage({ id: 'Contract.Annex.Toast.Delete.Title' }),
        {
          variant: 'success',
          autoHideDuration: 2000,
          anchorOrigin: {
            vertical: 'top',
            horizontal: 'right',
          },
        }
      );

      onSuccess();
      close();
    },
  });

  const onDelete = () => {
    deleteFetch.doFetch();
  };

  return (
    <DialogWithClose
      titleLabel={<FormattedMessage id="Contract.Annex.Dialog.Delete.Title" />}
      dialogActions={
        <Button onClick={onDelete}>
          <FormattedMessage id="Verbs.Delete" />
        </Button>
      }
    >
      <Stack gap={2}>
        <Typography sx={{ fontWeight: 600 }}>
          <FormattedMessage id="Contract.Annex.Dialog.Delete.Warning" />
        </Typography>
        <Typography>
          <FormattedMessage id="Contract.Annex.Dialog.Delete.Explanation" />
        </Typography>
      </Stack>
    </DialogWithClose>
  );
}
