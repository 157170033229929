import React, { lazy } from 'react';
import { Route, Routes } from 'react-router-dom';
import Box from '@mui/material/Box';
// import AppInsightsErrorBoundary from '@components/AppInsights/AppInsightsErrorBoundary';
import TenantAuthenticatedRoute from '@components/ProtectedRoutes/TenantAuthenticatedRoute';
import TenantRoleRoute from '@components/ProtectedRoutes/TenantRoleRoute';
import UnauthorizedView from '@views/Unauthorized/UnauthorizedView';
import NotFoundView from '@views/NotFoundView/NotFoundView';
// eslint-disable-next-line no-unused-vars
import ContractView from '@views/Tiptap/ContractEmbeddedView';
import SignatureProviderViewReturn from '@views/Signature/SignatureProviderViewReturn';

import { negociateType } from '@editor/plugins/types';
import {
  rolesAllowedToViewAdmin,
  rolesAllowedToViewSupplierDetails,
  rolesAllowedToViewSupplierRoutes,
} from './mgts-constants';
import SsoAutoConnect from './views/Signin/SsoAutoConnect';
import HelpDocumentView from './views/HelpDocument/HelpDocumentView';
import CguView from './views/Cgu/CguView';

const SigninView = React.lazy(() => import('./views/Signin/SigninView'));
const TenantRedirect = lazy(() => import('@views/Tenant/TenantRedirect'));
const AuthenticatedLayout = React.lazy(() =>
  import('@shells/mgts/mgts-layout')
);
const TemplatesView = lazy(() => import('./views/Admin/TemplatesView'));
const EditorView = lazy(() =>
  import('@views/Tiptap/Editor/EditorEmbeddedView')
);
const TesterView = lazy(() => import('@views/Tiptap/Reader/TesterView'));
const MGTSDetailsView = lazy(() =>
  import('./views/Assistant/SupplierDetails/MGTSDetailsView')
);
const DocumentStatsView = lazy(() =>
  import('./views/Assistant/DocumentStatsView')
);
const NotesView = lazy(() => import('./views/Assistant/NotesView'));
const VendorEntryView = lazy(() => import('./views/Assistant/VendorEntryView'));

const DashboardView = lazy(() => import('./views/Dashboard/DashboardView'));
const CampaignManagementView = lazy(() =>
  import('./views/CampaignManagement/CampaignManagementView')
);
const CampaignDocumentsView = lazy(() =>
  import('./views/CampaignDocuments/CampaignDocumentsView')
);
const FormView = lazy(() => import('./views/Vendor/FormView/FormView'));
const StatisticsView = lazy(() => import('./views/Statistics/StatisticsView'));
const AdminView = lazy(() => import('./views/Admin/AdminView'));
const InfoMangementView = lazy(() =>
  import('./views/Admin/InfoManagementView')
);
const ContractTypesView = lazy(() => import('./views/Admin/ContractTypesView'));
const ManageHelpView = lazy(() => import('./views/Admin/ManageHelpView'));
const FirstConnection = lazy(() =>
  import('./views/Signin/FirstConnectionPage')
);
const ResetConfirmation = lazy(() =>
  import('./views/Signin/ResetConfirmation')
);
const ForgotPassword = lazy(() => import('./views/Signin/ForgotPassword'));
const ForgotPasswordConfirmation = lazy(() =>
  import('./views/Signin/ForgotPasswordConfirmation')
);
const TemplateCompletionView = lazy(() =>
  import('@views/Administration/Templates/TemplateCompletionGenericView')
);
const ResetPassword = lazy(() => import('./views/Signin/ResetPassword'));
const TemplateWorkflowView = lazy(() =>
  import('@views/Administration/Templates/TemplateWorkflowView')
);


function TemplateWorkflowViewWrapper({ backUrl = '/admin/packages' }) {
  return (
    <Box sx={{ p: 1, pr: 0, width: 1 }}>
      <TemplateWorkflowView backUrl={backUrl} />
    </Box>
  );
}

function MGTSRoutes() {
  return (
    <Routes>
      <Route path="signin/*" element={<SigninView />} />
      <Route path="sso/*" element={<SsoAutoConnect />} />
      <Route
        path="authentication/FirstConnection"
        element={<FirstConnection />}
      />
      <Route
        path="authentication/resetConfirmation"
        element={<ResetConfirmation />}
      />
      <Route
        path="authentication/forgotPassword"
        element={<ForgotPassword />}
      />
      <Route
        path="authentication/forgotPasswordConfirmation"
        element={<ForgotPasswordConfirmation />}
      />
      <Route path="authentication/resetPassword" element={<ResetPassword />} />
      <Route
        path="signature/provider-view/return"
        element={<SignatureProviderViewReturn />}
      />
      <Route path="tenant/*">
        <Route path=":tenantId/*" element={<TenantRedirect />} />
      </Route>
      <Route path="*" element={<TenantAuthenticatedRoute />}>
        <Route path="*" element={<AuthenticatedLayout />}>
          <Route index element={<DashboardView />} />
          <Route path="help" element={<HelpDocumentView />} />
          <Route path="cgu" element={<CguView />} />
          <Route
            path="supplier/folder"
            element={
              <TenantRoleRoute roles={rolesAllowedToViewSupplierRoutes} />
            }
          >
            <Route
              path=":supplierFolderId/reader/:contractId/*"
              element={
                <ContractView
                  closeWorkflowPane
                  backUrl={id => `/supplier/folder/${id}`}
                />
              }
            />
            <Route path=":id/*" element={<FormView />} />
          </Route>
          <Route
            path="statistics"
            element={
              <TenantRoleRoute roles={rolesAllowedToViewSupplierDetails} />
            }
          >
            <Route path="*" element={<StatisticsView />} />
          </Route>
          <Route
            path="supplier"
            element={
              <TenantRoleRoute roles={rolesAllowedToViewSupplierDetails} />
            }
          >
            <Route path="*" element={<MGTSDetailsView />} />
          </Route>
          <Route
            path="document-stats"
            element={
              <TenantRoleRoute roles={rolesAllowedToViewSupplierDetails} />
            }
          >
            <Route path="*" element={<DocumentStatsView />} />
          </Route>
          <Route
            path="vendor/folder/:id/*"
            element={
              <TenantRoleRoute roles={rolesAllowedToViewSupplierDetails} />
            }
          >
            <Route path="*" element={<VendorEntryView />} />
          </Route>
          ;
          <Route
            path="reader/:supplierFolderId/:contractId/*"
            element={
              <TenantRoleRoute roles={rolesAllowedToViewSupplierDetails} />
            }
          >
            <Route
              path="*"
              element={
                <ContractView
                  closeWorkflowPane
                  backUrl={id => `/supplier/folder/${id}`}
                />
              }
            />
          </Route>
          <Route
            path="notes"
            element={
              <TenantRoleRoute roles={rolesAllowedToViewSupplierDetails} />
            }
          >
            <Route path="*" element={<NotesView />} />
          </Route>
          <Route
            path="admin"
            element={<TenantRoleRoute roles={rolesAllowedToViewAdmin} />}
          >
            <Route index element={<AdminView />} />
            <Route
              path="campaign-management"
              element={<CampaignManagementView />}
            />
            <Route
              path="campaign-documents"
              element={<CampaignDocumentsView />}
            />
            <Route path="info-management" element={<InfoMangementView />} />
            <Route path="templates">
              <Route
                path="editor/:id"
                element={
                  <EditorView
                    negociateType={negociateType.TrackChanges}
                    backUrl="/admin/templates"
                  />
                }
              />
              <Route path="editor/:id/test" element={<TesterView />} />
              <Route
                path="workflow/:id/*"
                element={
                  <TemplateWorkflowViewWrapper backUrl="/admin/templates" />
                }
              />
              <Route
                path="completion/:id"
                element={<TemplateCompletionView backUrl="/admin/templates" />}
              />
              <Route path="*" index element={<TemplatesView />} />
            </Route>
            <Route path="contract-types" element={<ContractTypesView />} />
            <Route path="manage-help/*" element={<ManageHelpView />} />
          </Route>
          <Route path="unauthorized" element={<UnauthorizedView />} />
          <Route path="*" element={<NotFoundView />} />
        </Route>
      </Route>
    </Routes>
  );
}

export default MGTSRoutes;
