import FormikProvider from '@components/Formik/FormikProvider';
import { Button } from '@mui/material';
import { FormattedMessage } from 'react-intl';
import * as Yup from 'yup';
import React from 'react';
import DialogWithClose from '@components/Dialog/DialogWithClose';
import { useFormikContext } from 'formik';
import WorkflowContributorAddModalSearchForm from './WorkflowContributorAddSearchForm';

const defaultSchema = Yup.object().shape({
  mode: Yup.string().required('Generic.Fields.Required'),
  selectedContributor: Yup.object()
    .when('mode', {
      is: mode => mode === 'search',
      then: Yup.object().required('Generic.Fields.Required').nullable(true),
    })
    .nullable(true),
  workflowAccess: Yup.string()
    .when('mode', {
      is: mode => mode === 'search',
      then: Yup.string().required('Generic.Fields.Required').nullable(true),
    })
    .nullable(true),
});

const defaultValues = {
  mode: 'search',
  selectedContributor: null,
  workflowAccess: null,
};

function InnerWorkflowContributorAddModal({
  showSignature,
  hideNewUser,
  schema,
  contributorType,
}) {
  const formik = useFormikContext();

  return (
    <DialogWithClose
      titleLabel={
        <FormattedMessage id="Workflow.Contributor.Modal.Search.Title" />
      }
      dialogActions={
        <Button color="primary" type="submit">
          <FormattedMessage
            id={formik.values?.mode === 'search' ? 'Verbs.Add' : 'Verbs.Next'}
          />
        </Button>
      }
    >
      <WorkflowContributorAddModalSearchForm
        showSignature={showSignature}
        hideNewUser={hideNewUser}
        schema={schema}
        contributorType={contributorType}
      />
    </DialogWithClose>
  );
}

export default function WorkflowContributorAddModal({
  onSubmit,
  showSignature,
  hideNewUser,
  schema,
  contributorType,
}) {
  const onSubmitForm = values => {
    onSubmit(values);
  };
  return (
    <FormikProvider
      schema={defaultSchema}
      initialValues={defaultValues}
      onSubmit={onSubmitForm}
      validateOnBlur={false}
    >
      <InnerWorkflowContributorAddModal
        showSignature={showSignature}
        hideNewUser={hideNewUser}
        schema={schema}
        contributorType={contributorType}
      />
    </FormikProvider>
  );
}
