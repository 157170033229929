import React from 'react';
import Stack from '@mui/material/Stack';
import Spinner from '@components/Spinner';

export default function Fetching({ fetching, children, ...props }) {
  if (!fetching) {
    if(!children)
      return null;
    return children;
  }

  return (
    <Stack alignItems="center" justifyContent="center" {...props}>
      <Spinner />
    </Stack>
  );
}
