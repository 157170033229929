import React from 'react';
import { Routes, Route } from 'react-router-dom';
import DialogOutlet from './DialogOutlet';

export default function DialogOutletRoutes({ path, onClose, children }) {
  return (
    <Routes>
      <Route path={path} element={<DialogOutlet open onClose={onClose} />}>
        {children}
      </Route>
    </Routes>
  );
}
