import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import DialogActions from '@mui/material/DialogActions';
import Button from '@mui/material/Button';

function DialogActionsWithClose({
  children,
  onClose,
  closeLabel = <FormattedMessage id="Verbs.Cancel" />,
  disabled,
  ButtonProps = {},
  ...props
}) {
  return (
    <DialogActions {...props}>
      <Button
        onClick={onClose}
        variant="outlined"
        color="error"
        disabled={disabled}
        {...ButtonProps}
      >
        {closeLabel}
      </Button>
      {children}
    </DialogActions>
  );
}

DialogActionsWithClose.propTypes = {
  onClose: PropTypes.func.isRequired,
  children: PropTypes.node,
  closeLabel: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  // eslint-disable-next-line react/forbid-prop-types
  ButtonProps: PropTypes.object,
};

DialogActionsWithClose.defaultProps = {
  children: null,
  closeLabel: <FormattedMessage id="Verbs.Cancel" />,
  ButtonProps: {},
};

export default DialogActionsWithClose;
