import React from 'react';
import { Stack } from "@mui/system";
import { PdfViewer } from '@components/Viewer/Viewer';

const pdfUrls = {
  cguUrl:
    'https://scmv3prodstorage.blob.core.windows.net/public-blobs/mgts/CGU.pdf',
};

function CguView() {
  return (
    <Stack direction="column" sx={{ width: '100%' }}>
      <PdfViewer fileUrl={pdfUrls.cguUrl} />
    </Stack>
  );
}
export default CguView;
