import React, { useState } from 'react';
import Stack from '@mui/material/Stack';
import FormikTextField from '@components/Formik/FormikTextField';
import ErrorMessage from '@components/ErrorMessage/ErrorMessage';
import useDebounced from '@hooks/useDebounced';
import useFetch from '@hooks/useFetch';
import {
  Autocomplete,
  CircularProgress,
  InputAdornment,
  TextField,
} from '@mui/material';
import { Field, useFormikContext } from 'formik';
import InsertDriveFileIcon from '@mui/icons-material/InsertDriveFile';
import { FormattedMessage } from 'react-intl';

export function getDocumentDriveUrl({ folderId }) {
  return `documents/drive?parentId=${
    folderId ? encodeURIComponent(folderId) : ''
  }`;
}


export default function AnnexForm({
  disabled,
  currentContractId,
  folderId,
}) {
  const [searchValue, setSearchValue] = useState('');
  const debouncedSearchValue = useDebounced(searchValue, 500);
  const { setFieldValue } = useFormikContext();

  const {
    data: searchResults,
    error,
    fetching: searchResultsFetching,
  } = useFetch({
    url:
      `documents/filters/document-available-annex?` +
      `query=${encodeURIComponent(debouncedSearchValue)}` +
      `${folderId ? `&parentId=${folderId}` : ''}`,
    method: 'GET',
    lazy: !debouncedSearchValue,
  });

  const handleSearchTextChange = (
    event
  ) => {
    setSearchValue(event.target.value);
  };

  const handleDocumentSelect = (event, selectedDocument) => {
    const documentId = selectedDocument?.id || '';
    const documentName = selectedDocument?.name || '';
    setFieldValue('documentId', documentId);
    setFieldValue('documentName', documentName);
    setFieldValue('name', documentName);
  };

  return (
    <Stack gap={2}>
      <Field name="documentName" required>
        {({ field }) => (
          <Autocomplete
            {...field}
            size="small"
            required
            options={
              searchResults?.items?.filter(
                (x) => x.id !== currentContractId
              ) || []
            }
            getOptionLabel={(option) => option.name ?? option}
            fullWidth
            onInputChange={handleSearchTextChange}
            onChange={handleDocumentSelect}
            loading={searchResultsFetching}
            noOptionsText={<FormattedMessage id='Contract.Annex.Dialog.Add.SelectDocument.NoResult'/>}
            isOptionEqualToValue={(option, value) =>
              typeof value === 'string'
                ? option?.name === value
                : option?.name === value?.name
            }
            renderInput={(params) => (
              <TextField
                {...params}
                label={<FormattedMessage id='Contract.Annex.Dialog.Add.SelectDocumentLabel' />}
                required
                InputProps={{
                  ...params.InputProps,
                  startAdornment: (
                    <InputAdornment position="start">
                      <InsertDriveFileIcon />
                    </InputAdornment>
                  ),
                  endAdornment: (
                    <>
                      {searchResultsFetching ? (
                        <CircularProgress color="inherit" size={20} />
                      ) : null}
                      {params.InputProps.endAdornment}
                    </>
                  ),
                }}
                sx={{mt: 1}}
              />
            )}
          />
        )}
      </Field>
      <ErrorMessage error={error} />
      <FormikTextField
        required
        name="name"
        label={<FormattedMessage id='Contract.Annex.Dialog.Add.NameLabel' />}
        disabled={disabled}
      />
    </Stack>
  );
}
