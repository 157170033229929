import FormikProvider from '@components/Formik/FormikProvider';
import { useMemo } from 'react';
import { FormattedMessage } from 'react-intl';
import * as Yup from 'yup';
import useFetch from '@hooks/useFetch';
import DialogWithClose from '@components/Dialog/DialogWithClose';
import { Button } from '@mui/material';
import Fetching from '@components/Fetching/Fetching';
import { useOutletContext } from 'react-router-dom';
import ErrorMessage from '@components/ErrorMessage/ErrorMessage';
import { useSnackbar } from 'notistack';
import AnnexImportForm from './AnnexImportForm';

const schema = Yup.object().shape({
  file: Yup.mixed().nullable().required('Generic.Fields.Required'),
  name: Yup.string().required('Generic.Fields.Required'),
});



export default function AddAnnexFromFileModal({
  disabled,
  fetching,
  onSuccess,
  contractId,
}) {
  const { close } = useOutletContext();
  const { enqueueSnackbar } = useSnackbar();
  const initialValues = useMemo(
    () => ({
      file: null,
      name: '',
    }),
    []
  );
  const addAnnexFetch = useFetch({
    url: `documents/${contractId}/annexes/import`,
    method: 'POST',
    noContentType: true,
    onSuccess: (response) => {
      enqueueSnackbar(
        `L'annexe "${response?.data?.name}" a bien été importé`,
        {
          variant: 'success',
          autoHideDuration: 2000,
          anchorOrigin: {
            vertical: 'top',
            horizontal: 'right',
          },
        }
      );

      onSuccess();
      close();
    },
  });
  const onSubmit = (values) => {
    const formData = new FormData();
    formData.append('File', values.file);
    formData.append('Name', values.name);

    addAnnexFetch.doFetch({
      body: formData,
    });
  };

  return (
    <FormikProvider
      schema={schema}
      initialValues={initialValues}
      onSubmit={onSubmit}
      validateOnBlur={false}
      enableReinitialize
    >
      <DialogWithClose
        titleLabel={<FormattedMessage id="Contract.Annex.Dialog.Add.Title" />}
        dialogActions={
          <Button type="submit" disabled={disabled}>
            <FormattedMessage
              id="UserAccounts.EditAccountModal.Buttons.Save.Label"
              defaultMessage="Importer"
            />
          </Button>
        }
      >
        <Fetching sx={{ minHeight: '100px' }} fetching={fetching}>
          <AnnexImportForm disabled={disabled} />
        </Fetching>
        <ErrorMessage sx={{ mt: 2 }} error={addAnnexFetch.error} />
      </DialogWithClose>
    </FormikProvider>
  );
}
