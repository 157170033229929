import React from 'react';
import Fetching from '@components/Fetching/Fetching';
import useFetch from '@hooks/useFetch';
import { Navigate, useNavigate, useSearchParams } from 'react-router-dom';

export default function SignatureProviderViewReturn() {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();

  const getSearchParam = name => searchParams.get(name) ?? '';
  const documentId = getSearchParam('documentId');
  const provider = getSearchParam('provider');
  const signatureId = getSearchParam('signatureId');

  const goToDocument = path => {
    if (path) {
      navigate(path);
    } else {
      navigate('/');
    }
  };

  const { doFetch } = useFetch({
    url: `/contracts/${documentId}`,
    cachePolicy: 'network-only',
    onSuccess: data => {
      goToDocument(`/reader/${data.data.document.folderId}/${documentId}`);
    },
    onError: () => {
      goToDocument();
    },
  });

  const { fetching } = useFetch({
    lazy: !documentId,
    url: `/contracts/signature/provider-view/return`,
    method: 'PUT',
    init: {
      body: {
        signatureId,
        signatureProviderName: provider,
      },
    },
    onSuccess: () => {
      doFetch();
    },
    onError: () => doFetch(),
  });

  if (!documentId) return <Navigate to="/" replace />;

  return <Fetching fetching={fetching} />;
}
