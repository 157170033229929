import { useIntl } from 'react-intl';
import { useTheme } from '@mui/material';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import {
  useParams,
  useSearchParams,
  useNavigate,
  Routes,
  Route,
  useLocation,
} from 'react-router-dom';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Fetching from '@components/Fetching/Fetching';
import WorkflowStepperWrapper from '@components/Workflow/WorkflowStepperWrapper';
import useFetch from '@hooks/useFetch';
import { localeState } from '@recoil/locale';
import { useRecoilValue } from 'recoil';
import useMessage from '@rottitime/react-hook-message-event';
import { useSnackbar } from 'notistack';
import ErrorBoundary from '@components/ErrorBoundary/ErrorBoundary';
import ContractWorkflowView from '@components/Workflow/ContractWorkflowView';
import { useState, useMemo } from 'react';
import useTenantRoles from '@hooks/useTenantRoles';
import { tenantRoleNames } from '@shells/izyworld/izyworld-constants';
import ContractAnnexView from './ContractAnnexView';
import ContractFetcherProvider from './components/Context/ContractFetchContext';

function ContractEmbeddedIFrame({ contractId, combined }) {
  if (!process.env.REACT_APP_EMBEDDED_URI) {
    throw new Error('Embedded uri not set.');
  }
  const locale = useRecoilValue(localeState);
  const theme = useTheme();
  const location = useLocation();
  const primaryColor = theme.palette.primary.main.replace('#', '');
  const secondaryColor = theme.palette.secondary.main.replace('#', '');

  const url = `${process.env.REACT_APP_EMBEDDED_URI}?selector=root&view=contract&uid=${contractId}&locale=${locale}&primaryColor=${primaryColor}&secondaryColor=${secondaryColor}&token=`;
  const anchor = location?.hash ?? '' != null ? location.hash : '';
  return (
    <iframe
      src={`${url}${combined?.token.value}${anchor}`}
      title="embedded"
      width="100%"
      height="100%"
      style={{ border: 0 }}
    />
  );
}

export function ContractEmbeddedView({
  backUrl: backUrlProp = '/drive',
  hideWorkflow = false,
  hideTabs = false,
  closeWorkflowPane = false,
}) {
  const { formatMessage } = useIntl();
  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();

  const { contractId, supplierFolderId } = useParams();

  backUrlProp = `/document-stats?folderid=${supplierFolderId}`;
  const backUrl = searchParams.get('backUrl') || backUrlProp;


  const { data: combined, fetching: fetchingCombined } = useFetch({
    url: '/authentication/authenticate/current-combined',
  });

  const { fetching, data, doFetch } = useFetch({
    url: `/contracts/${contractId}?includeWorkflow=true&includeStatus=true`,
    cachePolicy: 'network-only',
  });

  const {
    data: annexesData,
    fetching: fetchingAnnexes,
    doFetch: doFetchAnnexes,
  } = useFetch({
    url: `documents/${contractId}/annexes`,
    method: 'GET',
    cachePolicy: 'network-only',
  });

  const { doFetch: doPostComment } = useFetch({
    method: 'POST',
    url: `contracts/${contractId}/comment`,
    cachePolicy: 'network-only',
  });

  useMessage('navigation', (send, payload) => {
    if (payload?.back === true) {
      navigate(backUrl);
    }
  });

  useMessage('comment', (send, payload) => {
    payload.documentFolderId = supplierFolderId;
    doPostComment({ body: payload });
  });

  useMessage('contract-workflow-step', (send, payload) => {
    if (payload?.completed === true) {
      enqueueSnackbar(
        formatMessage({ id: 'Contract.Workflow.Complete.Success' }),
        {
          variant: 'success',
          autoHideDuration: 2000,
          anchorOrigin: {
            vertical: 'top',
            horizontal: 'right',
          },
        }
      );
      doFetch();
    }
    if (payload?.rejected === true) {
      enqueueSnackbar(
        formatMessage({ id: 'Contract.Workflow.Reject.Success' }),
        {
          variant: 'success',
          autoHideDuration: 2000,
          anchorOrigin: {
            vertical: 'top',
            horizontal: 'right',
          },
        }
      );
      doFetch();
    }
    if (payload?.validated === true) {
      enqueueSnackbar(
        formatMessage({ id: 'Contract.Workflow.Validate.Success' }),
        {
          variant: 'success',
          autoHideDuration: 2000,
          anchorOrigin: {
            vertical: 'top',
            horizontal: 'right',
          },
        }
      );
      doFetch();
    }
    if (payload?.invalidated === true) {
      enqueueSnackbar(
        formatMessage({ id: 'Contract.Workflow.Invalidate.Success' }),
        {
          variant: 'success',
          autoHideDuration: 2000,
          anchorOrigin: {
            vertical: 'top',
            horizontal: 'right',
          },
        }
      );
      doFetch();
    }
  });

  useMessage('contract-signature', (send, payload) => {
    if (payload?.sended === true) {
      enqueueSnackbar(
        formatMessage({ id: 'Contract.Signature.Send.Success' }),
        {
          variant: 'success',
          autoHideDuration: 2000,
          anchorOrigin: {
            vertical: 'top',
            horizontal: 'right',
          },
        }
      );
      doFetch();
    }
    if (payload?.canceled === true) {
      enqueueSnackbar(
        formatMessage({ id: 'Contract.Signature.Cancel.Success' }),
        {
          variant: 'success',
          autoHideDuration: 2000,
          anchorOrigin: {
            vertical: 'top',
            horizontal: 'right',
          },
        }
      );
      doFetch();
    }
  });

  useMessage('contract-signature-provider-view', (send, payload) => {
    console.log({ payload });
    if (payload?.requested === true) {
      const url = payload.url ?? '';
      if (url) {
        window.location = url;
      }
    }
  });

  const [tabValue, setTabValue] = useState(0);

  const onTabChange = (_event, newTabValue) => setTabValue(newTabValue);

  return (
    <ContractFetcherProvider
    annexesData={annexesData}
    fetchingAnnexes={fetchingAnnexes}
    doFetchAnnexes={doFetchAnnexes}
  >
    <Fetching fetching={fetching || fetchingCombined} sx={{ flexGrow: 1 }}>
      <Box
        sx={{
          display: 'grid',
          gridTemplateColumns: 'auto 1fr',
          gridTemplateRows: 'auto 1fr',
          gridTemplateAreas: '"workflow tabs" "workflow content"',
          flexGrow: 1,
          overflow: 'hidden',
          position: 'relative',
        }}
      >
        {!hideWorkflow && (
          <Stack flexDirection="column" gridArea="workflow">
            <WorkflowStepperWrapper
              status={data?.status}
              workflow={data?.workflow}
              isWorkflowPaneOpen={!closeWorkflowPane}
            />
          </Stack>
        )}
        {!hideTabs && (
          <Box gridArea="tabs">
            <Tabs value={tabValue} onChange={onTabChange}>
              <Tab label="Document" value={0} />
              {/* <Tab label="Versions" value={1} disabled /> */}
              <Tab label="Annexes" value={2}  />
              {/* <Tab label="Notes" value={3} /> */}
            </Tabs>
          </Box>
        )}
        <Stack flexDirection="column" gridArea="content">
          {(hideTabs || tabValue === 0) && combined ? (
            <ErrorBoundary>
              <ContractEmbeddedIFrame
                contractId={contractId}
                combined={combined}
              />
            </ErrorBoundary>
          ) : null}
          {(hideTabs || tabValue === 2) && (
            <ContractAnnexView contractId={contractId}/>
          )}
        </Stack>
      </Box>
    </Fetching>
    </ContractFetcherProvider>
  );
}

function ContractView(props) {
  const { supplierFolderId } = useParams();
  const isSupplier = useTenantRoles({ roles: ['Fournisseur'] });

  const isAdministrators = useTenantRoles({
    roles: [tenantRoleNames.administrators],
  });

  const backUrl = useMemo(
    () =>
      supplierFolderId
        ? isAdministrators
          ? `/supplier/folder/${supplierFolderId}`
          : `/document-stats?folderid=${supplierFolderId}`
        : '..',
    []
  );

  return (
    <Routes>
      <Route
        path="*"
        element={
          <ErrorBoundary>
            <ContractEmbeddedView
              hideWorkflow={isSupplier}
              {...props}
              backUrl={backUrl}
            />
          </ErrorBoundary>
        }
      />
      <Route
        path="workflow/*"
        element={
          <ErrorBoundary>
            <ContractWorkflowView {...props} backUrl=".." />
          </ErrorBoundary>
        }
      />
    </Routes>
  );
}

export default ContractView;
