import React from 'react';
import FormikContributorPicker from '@components/Formik/FormikContributorPicker';
import FormikSelect from '@components/Formik/FormikSelect';
import {
  FormControlLabel,
  MenuItem,
  Radio,
  RadioGroup,
  Typography,
} from '@mui/material';
import { Stack } from '@mui/system';
import { WorkflowContributorAccess } from '@views/constants';
import { useFormikContext } from 'formik';
import { FormattedMessage } from 'react-intl';

function getWorkflowContributorMenuItems(showSignature) {
  if (showSignature) {
    return Object.values(WorkflowContributorAccess)
      .filter(v => Number(v) > 0)
      .map((key, index) => (
        // eslint-disable-next-line react/no-array-index-key
        <MenuItem key={`access-${index}`} value={key}>
          <FormattedMessage
            id={`Workflow.Contributor.Fields.Access.${key}.Label`}
          />
        </MenuItem>
      ));
  }

  return Object.values(WorkflowContributorAccess)
    .filter(v => Number(v) > 1 && Number(v) < 10)
    .map((key, index) => (
      // eslint-disable-next-line react/no-array-index-key
      <MenuItem key={`access-${index}`} value={key}>
        <FormattedMessage
          id={`Workflow.Contributor.Fields.Access.${key}.Label`}
        />
      </MenuItem>
    ));
}

export default function WorkflowContributorAddModalSearchForm({
  showSignature = false,
  hideNewUser = false,
  schema,
}) {
  const { values, setFieldValue } = useFormikContext();
  return (
    <Stack gap={2}>
      <Typography
        sx={{
          fontWeight: 700,
          fontSize: '0.8rem',
        }}
      >
        <FormattedMessage id="Workflow.Contributor.Modal.Search.Description" />
      </Typography>
      <RadioGroup
        value={values?.mode}
        onChange={(_event, value) => {
          setFieldValue('mode', value);
        }}
      >
        <FormControlLabel
          value="search"
          control={<Radio color="primary" />}
          label={
            <Typography
              sx={{
                fontSize: '0.8rem',
              }}
            >
              <FormattedMessage id="Workflow.Contributor.Modal.Search.Option.1.Title" />
            </Typography>
          }
        />
        {values?.mode === 'search' && (
          <Stack
            direction="row"
            width="100%"
            justifyContent="center"
            alignContent="center"
            spacing={2}
          >
            <Stack
              width={values?.selectedContributor !== null ? '50%' : '100%'}
            >
              <FormikContributorPicker
                required
                margin="dense"
                name="selectedContributor"
                label={
                  <FormattedMessage id="Workflow.Contributor.Modal.Search.Select.Label" />
                }
                emptyUser={schema}
              />
            </Stack>
            {values?.selectedContributor !== null && (
              <Stack
                width="50%"
                // sx={{ paddingTop: '16px', paddingBottom: '8px' }}
              >
                <FormikSelect
                  margin="dense"
                  name="workflowAccess"
                  required
                  label={
                    <FormattedMessage id="Workflow.Contributor.Fields.Access.Label" />
                  }
                >
                  {getWorkflowContributorMenuItems(showSignature)}
                </FormikSelect>
              </Stack>
            )}
          </Stack>
        )}
        {!hideNewUser && (
          <FormControlLabel
            value="add"
            control={<Radio color="primary" />}
            label={
              <Typography
                sx={{
                  fontSize: '0.8rem',
                }}
              >
                <FormattedMessage id="Workflow.Contributor.Modal.Search.Option.2.Title" />
              </Typography>
            }
          />
        )}
      </RadioGroup>
    </Stack>
  );
}
