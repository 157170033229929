import React, { useState } from 'react';
import { Outlet } from 'react-router-dom';
import Dialog from '@mui/material/Dialog';

function DialogOutlet({ open = false, onClose = () => {}, ...props }) {
  const [opened, setOpened] = useState(open);
  const close = () => setOpened(false);
  return (
    <Dialog
      open={opened}
      onClose={close}
      closeAfterTransition
      TransitionProps={{ onExited: onClose }}
      fullWidth
      maxWidth="md"
      {...props}
    >
      <Outlet context={{ close }} />
    </Dialog>
  );
}

export default DialogOutlet;
