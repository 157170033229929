import { Button, Card, CardContent, Typography } from '@mui/material';
import { Stack } from '@mui/system';
import InsertDriveFileIcon from '@mui/icons-material/InsertDriveFile';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import { FormattedDate, FormattedMessage } from 'react-intl';


function AnnexListItemCard({
  name,
  creationTime,
  onDownload,
  onUpdate,
  onDelete,
  selected = false,
}) {
  return (
    <Card
      variant="outlined"
      sx={{
        bgcolor: selected ? 'primary.main' : 'transparent',
        color: selected ? 'common.white' : 'text.primary',
      }}
    >
      <CardContent sx={{ py: 1, px: 2, '&:last-child': { pb: 1 } }}>
        <Stack
          display="flex"
          flexDirection="row"
          justifyContent="space-between"
        >
          <Stack
            display="flex"
            flexDirection="row"
            gap="10px"
            alignItems="center"
          >
            <InsertDriveFileIcon />
            <Typography variant="subtitle2">{name} - <FormattedDate value={creationTime} /></Typography>
          </Stack>
          <Stack display="flex" flexDirection="row" gap="10px">
            <Button
              variant="outlined"
              onClick={onDownload}
              size="small"
              startIcon={<FileDownloadIcon />}
            >
              <FormattedMessage id="Verbs.Download" />
            </Button>
            <Button
              variant="outlined"
              onClick={onUpdate}
              size="small"
              startIcon={<EditIcon />}
            >
              <FormattedMessage id="Verbs.Rename" />
            </Button>
            <Button
              variant="outlined"
              onClick={onDelete}
              color="error"
              size="small"
              startIcon={<DeleteIcon />}
            >
              <FormattedMessage id="Verbs.Delete" />
            </Button>
          </Stack>
        </Stack>
      </CardContent>
    </Card>
  );
}

export default AnnexListItemCard;
