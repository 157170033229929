import React from 'react';
import { FormattedMessage } from 'react-intl';
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import DialogWithClose from '@components/Dialog/DialogWithClose';

export default function NavigationModal({ title, buttons }) {
  return (
    <DialogWithClose
      closeColor="primary"
      closeLabel={<FormattedMessage id="Verbs.Cancel" />}
      titleLabel={title}
    >
      <Stack alignItems="center" justifyContent="center" sx={{ mt: 2 }} gap={1}>
        {buttons?.map((button, index) =>
          button.type === 'divider' ? (
            <Typography key={button.id ?? index} sx={{textTransform: 'lowercase'}}>
              <FormattedMessage id="Modal.Button.Or.Label" />
            </Typography>
          ) : (
            <Button
              key={button.id ?? index}
              {...button}
              color="primary"
              variant="outlined"
              sx={{
                py: 1,
                bgcolor: 'primary.200',
                borderColor: 'transparent',
                px: 2,
                color: 'primary.main',
                borderRadius: '6px',
                fontSize: 11,
                width: '320px',
                textAlign: 'center',
              }}
            >
              <FormattedMessage
                id={button.id}
                defaultMessage={button.defaultMessage}
              />
            </Button>
          )
        )}
      </Stack>
    </DialogWithClose>
  );
}
