import React from 'react';
import FormikProvider from '@components/Formik/FormikProvider';
import * as Yup from 'yup';
import { FormattedMessage } from 'react-intl';
import withTenantPermissions from '@components/withTenantPermissions';
import AccountForm from './AccountForm';

const GetSchemaFormik = (workflowAccessRequired = false) =>
  Yup.object().shape({
    firstName: Yup.string().required('Generic.Fields.Required'),
    lastName: Yup.string().required('Generic.Fields.Required'),
    email: Yup.string()
      .email('Generic.Fields.InvalidFormat')
      .required('Generic.Fields.Required'),
    phoneNumber: Yup.string(),
    roleIds: Yup.array().of(Yup.string()),
    groupIds: Yup.array().of(Yup.string()),
    workflowAccess: workflowAccessRequired
      ? Yup.string().required('Generic.Fields.Required')
      : Yup.string(),
  });

const initialValues = {
  firstName: '',
  lastName: '',
  email: '',
  phoneNumber: '',
  roleIds: [],
  groupIds: [],
  workflowAccess: '',
};

function AddAccountModal({
  roles,
  groups,
  initialGroups,
  hideEmptyList = false,
  showWorkflowAccess = false,
  initWorkflowAccessValue = undefined,
  disabledWorkflowAccess = false,
  showSignatureWorkflowAccess = false,
  title = <FormattedMessage id="UserAccounts.CreateNewAccount.Title.Label" />,
  fetching,
  onSubmit,
  fetch,
}) {

  return (
    <FormikProvider
      schema={GetSchemaFormik(showWorkflowAccess)}
      initialValues={
        initWorkflowAccessValue
          ? {
              ...initialValues,
              workflowAccess: initWorkflowAccessValue,
              groupIds:
                initialGroups?.map(initialGroup => initialGroup.id) ?? [],
            }
          : {
              ...initialValues,
              groupIds:
                initialGroups?.map(initialGroup => initialGroup.id) ?? [],
            }
      }
      onSubmit={onSubmit}
      validateOnBlur={false}
      enableReinitialize
    >
      <AccountForm
        title={title}
        actionButtonText="UserAccounts.EditAccountModal.Buttons.Save.Label"
        defaultActionButtonText="Valider"
        fetching={fetching || fetch?.fetching}
        fetch={fetch}
        roles={roles}
        groups={groups}
        hideEmptyList={hideEmptyList}
        showWorkflowAccess={showWorkflowAccess}
        showSignatureWorkflowAccess={showSignatureWorkflowAccess}
        disabledWorkflowAccess={disabledWorkflowAccess}
      />
    </FormikProvider>
  );
}

export const AddAccountModalWithPermissions =
  withTenantPermissions(AddAccountModal);

export default AddAccountModal;
