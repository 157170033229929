export const tenantRoleNames = {
  administrators: 'Administrators',
  juriste: 'Juriste',
  chefdepole: 'ChefDePole',
  assistant: 'Assistant',
  chefdeproduit: 'ChefDeProduit',
  fournisseur: 'Fournisseur',
  buyer: 'Acheteur',
  refExpert: 'Expert Référencement',
  negociator: 'Négociateur',
  logistic: 'Expert Logistique',
  env: 'Expert Environnement',
  quality: 'Expert Qualite',
  sav: 'Expert SAV',
  supplierSigners: 'Signataire Fournisseur',
  internals : 'Internal',
  externals: 'External',
};

export const rolesAllowedToViewAdmin = [
  tenantRoleNames.administrators,
  tenantRoleNames.juriste,
];

export const rolesAllowedToViewSupplierDetails = [
  tenantRoleNames.administrators,
  tenantRoleNames.juriste,
  tenantRoleNames.chefdepole,
  tenantRoleNames.assistant,
  tenantRoleNames.chefdeproduit,
  tenantRoleNames.buyer,
  tenantRoleNames.fournisseur,
  tenantRoleNames.supplierSigners,
  tenantRoleNames.internals,
];

export const rolesAllowedToViewEditButton = [
  tenantRoleNames.administrators,
  tenantRoleNames.juriste,
  tenantRoleNames.chefdepole,
  tenantRoleNames.buyer,
  tenantRoleNames.assistant,
  tenantRoleNames.chefdeproduit,
  tenantRoleNames.internals,
];
export const rolesAllowedToViewNegotiatingDocuments = [
  tenantRoleNames.administrators,
  tenantRoleNames.fournisseur,
  tenantRoleNames.supplierSigners
];

export const supplierOnly = [tenantRoleNames.fournisseur, tenantRoleNames.supplierSigners];

export const rolesAllowedToAllowModifications = [
  tenantRoleNames.administrators,
  tenantRoleNames.juriste,
  tenantRoleNames.chefdepole,
  tenantRoleNames.assistant,
  tenantRoleNames.chefdeproduit,
  tenantRoleNames.buyer,
  tenantRoleNames.internals
];

export const rolesAllowedToViewSupplierRoutes = [
  tenantRoleNames.fournisseur,
  tenantRoleNames.supplierSigners
];

export const rolesAllowedToViewFilters = [
  tenantRoleNames.administrators,
  tenantRoleNames.juriste,
  tenantRoleNames.chefdepole,
  tenantRoleNames.assistant,
  tenantRoleNames.chefdeproduit,
  tenantRoleNames.buyer,
  tenantRoleNames.internals
];

export const rolesAllowedToViewAll = [
  tenantRoleNames.administrators,
  tenantRoleNames.juriste,
  tenantRoleNames.refExpert,
];

export const rolesAllowedToViewContractNegotiator = [
  tenantRoleNames.administrators,
  tenantRoleNames.juriste,
  tenantRoleNames.chefdepole,
  tenantRoleNames.assistant,
  tenantRoleNames.chefdeproduit,
  tenantRoleNames.buyer,
  tenantRoleNames.internals
];

export const rolesAllowedToViewNotebook = [
  tenantRoleNames.administrators,
  tenantRoleNames.juriste,
  tenantRoleNames.chefdepole,
  tenantRoleNames.assistant,
  tenantRoleNames.chefdeproduit,
  tenantRoleNames.buyer,
  tenantRoleNames.internals
];
export const rolesAllowedToViewStepper = [
  tenantRoleNames.administrators,
  tenantRoleNames.juriste,
  tenantRoleNames.chefdepole,
  tenantRoleNames.assistant,
  tenantRoleNames.chefdeproduit,
  tenantRoleNames.buyer,
  tenantRoleNames.internals
];

export const documentsUrls = [
  { label: 'CampaignManagement.Document.Url.Supplier.Info', href: '/supplier' },
  {
    label: 'CampaignManagement.Document.Url.Documents.Info',
    href: '/document-stats',
  },
  { label: 'CampaignManagement.Document.Url.NoteBook', href: '/notes' },
  { label: 'CampaignManagement.Document.Url.Vendor', href: '/vendor' },
];

export const campaignUrls = [
  {
    label: 'CampaignManagementView.Urls.Role.Management',
    href: '/admin/campaign-management',
  },
  {
    label: 'CampaignManagementView.Urls.Campaign.Setting',
    href: '/admin/campaign-documents',
  },
];
