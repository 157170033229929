import { atom, selector } from 'recoil';
import { resolve } from '@utils/authentication';
import decode from 'jwt-decode';
import { systemRolesEnum } from '@views/constants';


const defaultState = resolve('tenant-token');

export const tenantAuthenticationState = atom({
  key: 'tenantAuthenticationState',
  default: defaultState,
});

export const tenantAuthenticationSelector = selector({
  key: 'tenantAuthenticationSelector',
  get: ({ get }) => get(tenantAuthenticationState),
  set: ({ set }, newValue) => {
    if (newValue === null) {
      localStorage.removeItem('tenant-token');
    } else {
      localStorage.setItem('tenant-token', JSON.stringify(newValue));
    }
    set(tenantAuthenticationState, newValue);
  },
});

export const tenantIsAuthenticatedSelector = selector({
  key: 'tenantIsAuthenticatedSelector',
  get: ({ get }) => get(tenantAuthenticationSelector) !== null,
});

export const tenantTokenSelector = selector({
  key: 'tenantTokenSelector',
  get: ({ get }) => {
    const state = get(tenantAuthenticationSelector);
    return state?.token?.value ?? '';
  },
});

export const tenantDecodedTokenSelector = selector({
  key: 'tenantDecodedTokenSelector',
  get: ({ get }) => {
    const value = get(tenantTokenSelector);
    return value ? decode(value) : null;
  },
});

export const tenantImpersonatorAuthenticationSelector = selector({
  key: 'tenantImpersonatorAuthenticationSelector',
  get: ({ get }) => {
    const state = get(tenantAuthenticationSelector);
    return state?.impersonator ?? null;
  },
});

export const tenantIsImpersonatingSelector = selector({
  key: 'tenantIsImpersonatingSelector',
  get: ({ get }) => Boolean(get(tenantImpersonatorAuthenticationSelector)),
});

export const tenantImpersonatorTokenSelector = selector({
  key: 'tenantImpersonatorTokenSelector',
  get: ({ get }) => {
    const ticket = get(tenantImpersonatorAuthenticationSelector);
    return ticket?.token?.value ?? '';
  },
});

export const tenantDecodedImpersonatorTokenSelector = selector({
  key: 'tenantDecodedImpersonatorTokenSelector',
  get: ({ get }) => {
    const value = get(tenantImpersonatorTokenSelector);
    return value ? decode(value) : null;
  },
});

export const tenantUserIdentifierSelector = selector({
  key: 'tenantUserIdentifierSelector',
  get: ({ get }) => {
    const decoded = get(tenantDecodedTokenSelector);
    return decoded ? decoded.nameid : null;
  },
});

export const tenantUserAvatarSelector = selector({
  key: 'tenantUserAvatarSelector',
  get: ({ get }) => {
    const decoded = get(tenantDecodedTokenSelector);
    const impersonator = get(tenantDecodedImpersonatorTokenSelector);
    return !decoded
      ? {}
      : {
          email: decoded.email,
          firstName: decoded.given_name ?? '',
          lastName: decoded.family_name ?? '',
          impersonator: !impersonator
            ? {}
            : {
                email: impersonator.email,
                firstName: impersonator.given_name ?? '',
                lastName: impersonator.family_name ?? '',
              },
        };
  },
});

export const tenantIdentifierSelector = selector({
  key: 'tenantIdentifierSelector',
  get: ({ get }) => {
    const value = get(tenantDecodedTokenSelector);
    const key =
      'https://schemas.seraphin.legal/2022/01/identity/claims/tenantidentifier';
    return value?.[key] ?? '';
  },
});

export const tenantParentIdentifierSelector = selector({
  key: 'tenantParentIdentifierSelector',
  get: ({ get }) => {
    const value = get(tenantDecodedTokenSelector);
    const key =
      'https://schemas.seraphin.legal/2022/01/identity/claims/tenantparentidentifier';
    return value?.[key] ?? '';
  },
});

export const tenantPermissionsSelector = selector({
  key: 'tenantPermissionsSelector',
  get: ({ get }) => {
    const decoded = get(tenantDecodedTokenSelector);
    if (!decoded) {
      return '';
    }
    return (
      decoded[
        'https://schemas.seraphin.legal/2022/01/identity/claims/permissions'
      ] ?? ''
    )
      .split('')
      .map(c => c.charCodeAt(0));
  },
});

export const tenantRolesSelector = selector({
  key: 'tenantRolesSelector',
  get: ({ get }) => {
    const decoded = get(tenantDecodedTokenSelector);
    if (!decoded) {
      return [];
    }
    const roles = decoded.role;
    return Array.isArray(roles) ? roles : [roles];
  },
});

export const tenantRolesIsInternalSelector = selector({
  key: 'tenantRolesIsInternalSelector',
  get: ({ get }) => {
    const roles = get(tenantRolesSelector);
    return !!roles.find(x => x === systemRolesEnum.Internal);
  },
});

export const tenantRolesIsExternalSelector = selector({
  key: 'tenantRolesIsExternalSelector',
  get: ({ get }) => {
    const roles = get(tenantRolesSelector);
    return roles.every(
      x =>
        x === systemRolesEnum.External ||
        (x !== systemRolesEnum.Internal && x !== systemRolesEnum.Administrator)
    );
  },
});

export const tenantTokenNavigated = atom({
  key: 'tenantTokenNavigated',
  default: true,
});
