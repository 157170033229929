import React, { useRef } from 'react';

function AutoConnect() {
  const mescouilles = useRef(null);
  React.useEffect(() => {
    mescouilles.current.submit();
  }, []);
  return (
    <form
      ref={mescouilles}
      action={`${process.env.REACT_APP_API_URI}/mgts/login`}
    >
      <input
        type="hidden"
        name="returnUrl"
        value={`${window.location.origin}`}
      />
    </form>
  );
}

export default AutoConnect;
