import { tenantIsImpersonatingSelector } from '@recoil/tenant-token';
import { useRecoilValue } from 'recoil';
import useThemeColorExists from './useThemeColorExists';

export default function useDelegationColor(baseColor) {
  const delegationColor = 'delegation';
  const isImpersonating = useRecoilValue(tenantIsImpersonatingSelector);
  const delegationColorExists = useThemeColorExists(delegationColor);
  const resultColor =
    isImpersonating && delegationColorExists ? delegationColor : baseColor;
  return resultColor ?? '';
}
