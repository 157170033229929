import { useCallback } from 'react';
import { useNavigate } from 'react-router-dom';

function useNavigateModal() {
  const navigate = useNavigate();
  return useCallback(
    (to, options) =>
      navigate(to, {
        ...(options ?? {}),
        state: {
          ...(options?.state ?? {}),
          modal: true,
        },
      }),
    [navigate]
  );
}

export default useNavigateModal;
