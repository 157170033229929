import React from 'react';
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import { FormattedMessage } from 'react-intl';

export default function ModalButtonGroup({
  handleClose,
  cancelButtonText,
  handleSubmit,
  submitButtonText,
  variant,
  sx,
  ...rest
}) {
  return (
    <Stack
      direction="row"
      justifyContent="center"
      alignItems="center"
      spacing={2}
      sx={{ mt: 2, ...(sx ?? {}) }}
      {...rest}
    >
      {handleClose ? (
        <Button
          onClick={handleClose}
          variant="outlined"
          color="error"
          size="large"
          sx={{ fontWeight: 700 }}
        >
          <FormattedMessage id={cancelButtonText ?? 'Cancel'} />
        </Button>
      ) : null}
      {handleSubmit ? (
        <Button
          onClick={handleSubmit}
          variant="contained"
          color={variant}
          size="large"
          type="submit"
          sx={{ fontWeight: 700 }}
        >
          <FormattedMessage id={submitButtonText ?? 'Validate'} />
        </Button>
      ) : null}
    </Stack>
  );
}

ModalButtonGroup.defaultProps = {
  variant: 'primary',
};
