import { useLayoutEffect } from 'react';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import {
  useSearchParams,
  useLocation,
  useNavigate,
  useMatch,
} from 'react-router-dom';

import {
  tenantAuthenticationState,
  tenantDecodedTokenSelector,
  tenantAuthenticationSelector,
} from '@recoil/tenant-token';
import decode from 'jwt-decode';

function TokenCapture({ children, chooseNavigation }) {
  const location = useLocation();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const tokenParam = useMatch('/capture') ? searchParams.get('token') : null;
  const decodedTokenParam = tokenParam ? decode(tokenParam) : null;
  const tenantState = useRecoilValue(tenantAuthenticationState);
  const setTenantState = useSetRecoilState(tenantAuthenticationSelector);
  const decodedTenantToken = useRecoilValue(tenantDecodedTokenSelector);

  useLayoutEffect(() => {
    if (tokenParam) {
      const state = {
        fromSearchParam: true,
        token: decodedTokenParam.token,
        refreshToken: decodedTokenParam.refreshToken,
      };

      setTenantState(state);
    }
  }, [tokenParam]);

  useLayoutEffect(() => {
    if (tokenParam && tenantState?.fromSearchParam) {
      const { navigationType, navigationElement } = decodedTenantToken;

      const to = chooseNavigation({
        type: navigationType,
        element: navigationElement,
      });

      navigate(to, {
        state: location.state,
        replace: true,
      });
    }
  }, [chooseNavigation, location, tokenParam, tenantState, decodedTenantToken]);

  return tokenParam ? null : children;
}

export default TokenCapture;
