import React from 'react';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import { PdfViewer } from '@components/Viewer/Viewer';
import { tenantRolesSelector } from '@recoil/tenant-token';
import { useRecoilValue } from 'recoil';
import { rolesAllowedToViewSupplierRoutes } from '@shells/mgts/mgts-constants';

const pdfUrls = {
  supplierUrl:
    'https://scmv3prodstorage.blob.core.windows.net/public-blobs/mgts/Mobivia%20Contracts%20Platform%20-%20BESOIN%20D%20AIDE%20FOURNISSEUR.pdf',
  lvcInternalUrl:
    'https://scmv3prodstorage.blob.core.windows.net/public-blobs/mgts/Mobivia%20Contracts%20Platform%20-%20Internal%20User%20Guide.pdf',
};

export default function HelpDocumentView() {
  const userRoles = useRecoilValue(tenantRolesSelector);

  const isSupplier = userRoles?.some(role =>
    rolesAllowedToViewSupplierRoutes?.includes(role)
  );

  return (
    <Stack direction="column" sx={{ width: '100%' }}>
      {isSupplier ? (
        <Box sx={{ padding: '30px' }}>
          <PdfViewer fileUrl={pdfUrls.supplierUrl} />
        </Box>
      ) : (
        <Box sx={{ padding: '30px' }}>
          <PdfViewer fileUrl={pdfUrls.lvcInternalUrl} />
        </Box>
      )}
    </Stack>
  );
}
