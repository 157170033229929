export const sortModes = {
  default: 'default',
  nameAtoZ: 'name-a-to-z',
  nameZtoA: 'name-z-to-a',
  ownerNameAtoZ: 'owner-name-a-to-z',
  ownerNameZtoA: 'owner-name-z-to-a',
  statusAsc: 'status-asc',
  statusDesc: 'status-desc',
  dateModifiedNewestFirst: 'date-modified-newest-first',
  dateModifiedOldestFirst: 'date-modified-oldest-first',
  apiNameAsc: 'name_asc',
  apiNameDesc: 'name_desc',
  apiOwnerNameAsc: 'owner-name_asc',
  apiOwnerNameDesc: 'owner-name_desc',
  apiStatusAsc: 'status_asc',
  apiStatusDesc: 'status_desc',
  apiDateModifiedAsc: 'date-modified_asc',
  apiDateModifiedDesc: 'date-modified_desc',
} ;

export const documentStatuses = {
  pending: {
    value: { status: 1 },
    name: 'pending',
    labelMessageId: 'Document.Card.pending',
  },
  finished: {
    value: { status: 2 },
    name: 'finished',
    labelMessageId: 'Document.Card.finished',
  },
  signing: {
    value: { status: 3 },
    name: 'signing',
    labelMessageId: 'Document.Card.signing',
  },
  signed: {
    value: { status: 4 },
    name: 'signed',
    labelMessageId: 'Document.Card.signed',
  },
  rejected: {
    value: { status: 5 },
    name: 'rejected',
    labelMessageId: 'Document.Card.rejected',
  },
  abandoned: {
    value: { status: 6 },
    name: 'abandoned',
    labelMessageId: 'Document.Card.abandoned',
  },
} ;

export const roleTypeEnum = {
  Role: 1,
  Group: 2,
};

export const workflowContributorTypes = {
  user: 1,
  role: 2,
};

export const systemRolesEnum = {
  Internal: 'Internal',
  External: 'External',
  Administrator: 'Administrators',
};

export const WorkflowContributorAccess = {
  none : 0,
  sign : 1,
  form : 2,
  formReview : 3,
  review : 4,
  comment : 5,
  readOnly : 6,
  sendsign : 7,
  formReviewWithoutValidation : 8,
  reviewWithoutValidation: 9,
}

export const TermSheetSchemaValueConnection = {
  Variables : 1,
  BlocExist : 2,
  OptionSelected : 3,
}



export const termSheetSchemaValueConnectionLabel =
  {
    [TermSheetSchemaValueConnection.Variables]: {
      fallback: 'Variable contenue dans le bloc',
      i18nId: '',
      order: 3,
    },
    [TermSheetSchemaValueConnection.BlocExist]: {
      fallback: 'Présence du bloc',
      i18nId: '',
      order: 1,
    },
    [TermSheetSchemaValueConnection.OptionSelected]: {
      fallback: 'Option(s) sélectionnée(s)',
      i18nId: '',
      order: 2,
    },
  };
