import React from 'react';
import MuiIconButton from '@mui/material/IconButton';
import { Link } from 'react-router-dom';

export default function IconButton({ to, ...props }) {
  const isLink = to !== null && to !== undefined;
  return (
    <MuiIconButton
      component={isLink ? Link : undefined}
      to={isLink ? to : undefined}
      {...props}
    />
  );
}
