import React, { useMemo } from 'react';
import Stack from '@mui/material/Stack';
import { Button, Typography } from '@mui/material';
import DragIndicatorIcon from '@mui/icons-material/DragIndicator';
import useFetch from '@hooks/useFetch';
import Fetching from '@components/Fetching/Fetching';
import DialogOutletRoutes from '@components/Dialog/DialogOutletRoutes';
import { Link, Route } from 'react-router-dom';
import NavigationModal from '@components/Modals/NavigationModal';
import { FormattedMessage, useIntl } from 'react-intl';
import { useSnackbar } from 'notistack';
import useNavigateModal from '@hooks/useNavigateModal';
import Dropzone from 'react-dropzone';
import AddAnnexFromDocumentModal from './components/Annexes/AddAnnexFromDocumentModal';
import AnnexList from './components/Annexes/AnnexList';
import DeleteAnnexModal from './components/Annexes/DeleteAnnexModal';
import EditAnnexModal from './components/Annexes/EditAnnexModal';
import AddAnnexFromFileModal from './components/Annexes/AddAnnexFromFileModal';
import { useContractFetchContext } from './components/Context/ContractFetchContext';

const getAddAnnexOptions = () => [
  {
    id: 'Contract.Annex.Button.New.Label',
    component: Link,
    to: `new`,
    state: { modal: true },
  },
  { type: 'divider' },
  {
    id: 'Contract.Annex.Button.Import.Label',
    component: Link,
    to: `import`,
    state: { modal: true },
  },
];



function ContractAnnexView({ contractId, folderId }) {
  const intl = useIntl();
  const navigateModal = useNavigateModal();
  const { annexesData, fetchingAnnexes, doFetchAnnexes } =
    useContractFetchContext();
  const annexes = annexesData?.annexes?.sort(
    (a, b) => a.orderId - b.orderId
  );
  const { enqueueSnackbar } = useSnackbar();
  const navigateToContract = () => navigateModal(`.`);

  const refresh = () => {
    navigateToContract();
    doFetchAnnexes();
  };

  const onDelete = (annexId) => {
    navigateModal(`annex-modals/annex/delete/${annexId}`);
  };

  const { doFetch: doGetPdfLink } = useFetch({
    method: 'GET',
    lazy: true,
    onSuccess: (response) => {
      window.open(response?.data?.uri, '_blank', 'noreferrer');
    },
    onError: (response) => {
      const dataError = 'data' in response ? (response.data) : null;
      const errors = dataError?.Errors ?? dataError?.errors;
      if (errors) {
        const listError = Object.values(errors)
          .flat()
          .map((key) =>
            intl.formatMessage({
              id: `Errors.${typeof key === 'object' ? key?.message : key}`,
            })
          );
          enqueueSnackbar(
            listError.join(', '),
            {
              variant: 'error',
              autoHideDuration: 2000,
              anchorOrigin: {
                vertical: 'top',
                horizontal: 'right',
              },
            }
          );

      }
    },
  });

  const onDownload = (annexId) => {
    doGetPdfLink({
      url: `documents/${contractId}/annexes/${annexId}/view`,
    });
  };

  const onUpdate = (annexId) => {
    navigateModal(`annex-modals/annex/update/${annexId}`);
  };
  const { doFetch: doUpdateOrder } = useFetch({
    url: `documents/${contractId}/annexes/order`,
    method: 'PUT',
    lazy: true,
    onSuccess: () => {
      enqueueSnackbar(
        intl.formatMessage({ id: 'Contract.Annex.Toast.Reorder.Title' }),
        {
          variant: 'success',
          autoHideDuration: 2000,
          anchorOrigin: {
            vertical: 'top',
            horizontal: 'right',
          },
        }
      );

    },
  });

  const addAnnexFetch = useFetch({
    url: `documents/${contractId}/annexes/import`,
    method: 'POST',
    noContentType: true,
    onSuccess: (response) => {
      enqueueSnackbar(
        `L'annexe "${response?.data?.name}" a bien été importé`,
        {
          variant: 'success',
          autoHideDuration: 2000,
          anchorOrigin: {
            vertical: 'top',
            horizontal: 'right',
          },
        }
      );
      refresh();
    },
  });

  const onMove = (annexesOrdered) => {
    doUpdateOrder({
      body: {
        documentAnnexOrder: annexesOrdered.map(x => ({
          id: x.id,
          orderId: x.orderId,
        })),
      },
    });
  };

  const addAnnexOptions = useMemo(() => getAddAnnexOptions(), []);

  return (
    <>
      <Dropzone noClick onDrop={(acceptedFiles) => {

        acceptedFiles.forEach((file) => {
          // console.log(file.nativeFiles)
          const formData = new FormData();
          formData.append('File', file);
          formData.append('Name', file.name);
          addAnnexFetch.doFetch({
            body: formData,
          });

        })


      }}>
        {({ getRootProps }) => (
          <div {...getRootProps()}>
            <Stack
              direction="column"
              sx={{ width: '100%', height: '100vh', display: 'block', mb: 5, p: '24px 48px 24px 48px' }}
            >
              <Stack
                direction="row"
                justifyContent="space-between"
                alignItems="center"
                sx={{ mb: 2 }}
              >
                <Typography
                  component="span"
                  variant="caption"
                  display="flex"
                  alignItems="center"
                >
                  <FormattedMessage id="Contract.Annew.List.Helper.Description" />{' '}
                  <DragIndicatorIcon />
                </Typography>
                <Button
                  sx={{ borderRadius: 10 }}
                  variant="contained"
                  onClick={() => navigateModal('annex-modals/annex/add')}
                >
                  <FormattedMessage id="Contract.Annex.List.Button.Add" />
                </Button>
              </Stack>
              <Fetching fetching={fetchingAnnexes}>
                <AnnexList
                  annexes={annexes}
                  onDelete={onDelete}
                  onDownload={onDownload}
                  onUpdate={onUpdate}
                  onMove={onMove}
                />
              </Fetching>
            </Stack>
          </div>
        )}
      </Dropzone>
      <DialogOutletRoutes path="annex-modals/*" onClose={navigateToContract}>
        <Route
          path="annex/add"
          element={
            <NavigationModal
              title={<FormattedMessage id="Contract.Annex.Dialog.Add.Title" />}
              buttons={addAnnexOptions}
            />
          }
        />
        <Route
          path="annex/add/new"
          element={
            <AddAnnexFromDocumentModal
              fetching={null}
              disabled={false}
              onSuccess={refresh}
              contractId={contractId}
              folderId={folderId}
            />
          }
        />
        <Route
          path="annex/add/import"
          element={
            <AddAnnexFromFileModal
              fetching={null}
              disabled={false}
              onSuccess={refresh}
              contractId={contractId}
            />
          }
        />
        <Route
          path="annex/delete/:annexId"
          element={
            <DeleteAnnexModal documentId={contractId} onSuccess={refresh} />
          }
        />
        <Route
          path="annex/update/:annexId"
          element={
            <EditAnnexModal documentId={contractId} onSuccess={refresh} />
          }
        />
      </DialogOutletRoutes>
    </>
  );
}

export default ContractAnnexView;
