import FormikProvider from '@components/Formik/FormikProvider';
import { useMemo } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import * as Yup from 'yup';
import useFetch from '@hooks/useFetch';
import DialogWithClose from '@components/Dialog/DialogWithClose';
import { Button } from '@mui/material';
import Fetching from '@components/Fetching/Fetching';
import { useOutletContext } from 'react-router-dom';
import ErrorMessage from '@components/ErrorMessage/ErrorMessage';
import { useSnackbar } from 'notistack';
import AnnexForm from './AnnexForm';

const schema = Yup.object().shape({
  name: Yup.string().required('Generic.Fields.Required'),
  documentId: Yup.string().required('Generic.Fields.Required'),
  documentName: Yup.string().required('Generic.Fields.Required'),
});

export default function AddAnnexFromDocumentModal({
  disabled,
  fetching,
  onSuccess,
  contractId,
  folderId,
}) {
  const { close } = useOutletContext();
  const { enqueueSnackbar } = useSnackbar();
  const initialValues = useMemo(
    () => ({
      name: '',
      documentId: '',
      documentName: '',
    }),
    []
  );
  const intl = useIntl();
  const addAnnexFetch = useFetch({
    method: 'POST',
    onSuccess: (response) => {
      enqueueSnackbar(
        intl.formatMessage(
          { id: 'Contract.Annex.Toast.Add.Title' },
          { name: response?.data?.name }
        ),
        {
          variant: 'success',
          autoHideDuration: 2000,
          anchorOrigin: {
            vertical: 'top',
            horizontal: 'right',
          },
        }
      );

      onSuccess();
      close();
    },
  });
  const onSubmit = (values) => {
    addAnnexFetch.doFetch({
      url: `documents/${contractId}/annexes`,
      body: {
        DocumentId: values.documentId,
        Name: values.documentName,
      },
    });
  };

  return (
    <FormikProvider
      schema={schema}
      initialValues={initialValues}
      onSubmit={onSubmit}
      validateOnBlur={false}
      enableReinitialize
    >
      <DialogWithClose
        titleLabel={<FormattedMessage id="Contract.Annex.Dialog.Add.Title" />}
        dialogActions={
          <Button type="submit" disabled={disabled}>
            <FormattedMessage
              id="UserAccounts.EditAccountModal.Buttons.Save.Label"
              defaultMessage="Importer"
            />
          </Button>
        }
      >
        <Fetching sx={{ minHeight: '100px' }} fetching={fetching}>
          <AnnexForm disabled={disabled} currentContractId={contractId} folderId={folderId}/>
        </Fetching>
        <ErrorMessage sx={{ mt: 2 }} error={addAnnexFetch.error} />
      </DialogWithClose>
    </FormikProvider>
  );
}
