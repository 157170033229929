import React from 'react';
import { useOutletContext } from 'react-router-dom';
import DialogContent from '@mui/material/DialogContent';

import Fetching from '@components/Fetching/Fetching';
import DialogTitleWithClose from './DialogTitleWithClose';
import DialogActionsWithClose from './DialogActionsWithClose';

function DialogWithClose({
  children,
  titleLabel,
  dialogActions,
  fetching,
  disabled,
}) {
  const { close } = useOutletContext();
  return (
    <>
      <DialogTitleWithClose onClose={close}>{titleLabel}</DialogTitleWithClose>
      <DialogContent>
        <Fetching fetching={fetching} sx={{ pb: 4 }}>
          {children}
        </Fetching>
      </DialogContent>
      {!fetching && (
        <DialogActionsWithClose onClose={close} disabled={disabled}>
          {dialogActions}
        </DialogActionsWithClose>
      )}
    </>
  );
}

export default DialogWithClose;
