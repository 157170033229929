import React, { useEffect, useRef } from 'react';
import Stack from '@mui/material/Stack';
import FormikTextField from '@components/Formik/FormikTextField';
import { useFormikContext } from 'formik';
import FormikFileField from '@components/UploadFileInput/UploadFileInput';
import { FormattedMessage } from 'react-intl';

export function getDocumentDriveUrl({ folderId }) {
  return `documents/drive?parentId=${
    folderId ? encodeURIComponent(folderId) : ''
  }`;
}



export default function AnnexImportForm({ disabled }) {
  const { values, setFieldValue } =
    useFormikContext();
  const prevFileRef = useRef(null);

  useEffect(() => {
    if (prevFileRef.current !== values.file) {
      if (values.file === null) {
        setFieldValue('name', '');
      } else {
        setFieldValue('name', values.file?.name);
      }
    }
    prevFileRef.current = values.file;
  }, [values.file, setFieldValue]);
  return (
    <Stack gap={2}>
      <FormikFileField
        name="file"
        required
        accept={['.pdf']}
        label={
          <FormattedMessage
            id="Contract.Annex.Dialog.Add.ImportLabel"
          />
        }
      />
      <FormikTextField
        required
        name="name"
        label={<FormattedMessage id='Contract.Annex.Dialog.Add.NameLabel' />}
        color="secondary"
        margin="dense"
        disabled={disabled}
      />
    </Stack>
  );
}
