import React, { useMemo } from 'react';
import FormikProvider from '@components/Formik/FormikProvider';
import * as Yup from 'yup';
import { useOutletContext, useParams } from 'react-router-dom';
import useFetch from '@hooks/useFetch';
import DialogWithClose from '@components/Dialog/DialogWithClose';
import { FormattedMessage, useIntl } from 'react-intl';
import { Button, Stack } from '@mui/material';
import FormikTextField from '@components/Form/TextField';
import Fetching from '@components/Fetching/Fetching';
import ErrorMessage from '@components/ErrorMessage/ErrorMessage';

import { useSnackbar } from 'notistack';



const schema = Yup.object().shape({
  name: Yup.string().required('Generic.Fields.Required'),
});

export default function EditAnnexModal({
  onSuccess,
  documentId,
}) {
  const { annexId } = useParams();
  const { enqueueSnackbar } = useSnackbar();
  const { close } = useOutletContext();
  const annexFetch = useFetch({
    url: `documents/${documentId}/annexes/${annexId}`,
  });

  const annex = annexFetch.data;
  const initialValues = useMemo(
    () => ({
      name: annex?.name ?? '',
    }),
    [annex]
  );
  const intl = useIntl();
  const editFetch = useFetch({
    url: `documents/${documentId}/annexes/${annexId}`,
    method: 'PUT',
    onSuccess: () => {
      enqueueSnackbar(
        intl.formatMessage({ id: 'Contract.Annex.Toast.Edit.Title' }),
        {
          variant: 'success',
          autoHideDuration: 2000,
          anchorOrigin: {
            vertical: 'top',
            horizontal: 'right',
          },
        }
      );

      onSuccess();
      close();
    },
  });

  const onSubmit = (values) => {
    editFetch.doFetch({ body: { ...annex, ...values } });
  };

  return (
    <FormikProvider
      schema={schema}
      initialValues={initialValues}
      onSubmit={onSubmit}
      validateOnBlur={false}
      enableReinitialize
    >
      <DialogWithClose
        titleLabel={<FormattedMessage id="Contract.Annex.Dialog.Edit.Title" />}
        dialogActions={
          <Button type="submit">
            <FormattedMessage id="Verbs.Modify" defaultMessage="Importer" />
          </Button>
        }
      >
        <Fetching sx={{ minHeight: '100px' }} fetching={annexFetch.fetching}>
          <Stack>
            <FormikTextField
              required
              name="name"
              label={<FormattedMessage id='Contract.Annex.Dialog.Add.NameLabel'/>}
              color="secondary"
              margin="dense"
            />
          </Stack>
        </Fetching>
        <ErrorMessage sx={{ mt: 2 }} error={annexFetch.error} />
      </DialogWithClose>
    </FormikProvider>
  );
}
