import React from 'react';



const ContractFetchContext = React.createContext({
  annexesData: undefined,
  fetchingAnnexes: false,
  doFetchAnnexes: () => null,
  notesData: undefined,
  fetchingNotes: false,
  doFetchNotes: () => null,
});

export const useContractFetchContext = () => {
  const context = React.useContext(ContractFetchContext);
  if (!context) {
    throw Error(
      'ContractFetcherContext is not defined. Did you forget to wrap your component in a ContractFetcherProvider?'
    );
  }
  return context;
};

function ContractFetcherProvider({
  annexesData,
  fetchingAnnexes,
  doFetchAnnexes,
  notesData,
  fetchingNotes,
  doFetchNotes,
  children = null,
}) {
  const value = React.useMemo(
    () => ({
      annexesData,
      fetchingAnnexes,
      doFetchAnnexes,
      notesData,
      fetchingNotes,
      doFetchNotes,
    }),
    [
      annexesData,
      doFetchAnnexes,
      doFetchNotes,
      fetchingAnnexes,
      fetchingNotes,
      notesData,
    ]
  );
  return (
    <ContractFetchContext.Provider value={value}>
      {children}
    </ContractFetchContext.Provider>
  );
}

export default ContractFetcherProvider;
