import React from 'react';

import useTenantPermissions from '@hooks/useTenantPermissions';

function PermissionsComponent({
  permissions = [],
  mode = 'at-least',
  invert = false,
  children,
}) {
  const hasPermission = useTenantPermissions({ permissions, mode });
  if (invert && hasPermission) return null;
  if (!invert && !hasPermission) return null;
  return children;
}

const withPermissions = WrappedComponent =>
  function withPermissionsWrapped({
    permissions = [],
    mode = 'at-least',
    invert = false,
    ...rest
  }) {
    return (
      <PermissionsComponent
        permissions={permissions}
        mode={mode}
        invert={invert}
      >
        <WrappedComponent {...rest} />
      </PermissionsComponent>
    );
  };

export default withPermissions;
