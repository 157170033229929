import {
  createContext,
  useContext,
  React
} from 'react';



export const defaultAppContextValue = {
  contract: {
    workflow: { hidden: false },
    versions: { hidden: false },
    annexes: { hidden: false },
    notes: { hidden: false },
    useCustomUi: () => ({}),
  },
  dashboard: { disabled: false },
  drive: {
    hideCreate: false,
    hideSearchExport: false,
    hideProgress: false,
    filters: { hideCreatedBy: false },
  },
  calendar: { disabled: false },
  statistics: { disabled: false },
  headers: {
    useCustomUserMenu: () => null,
  },
};

const AppContext = createContext(defaultAppContextValue);

export function AppContextProvider({
  value,
  children,
}) {
  return <AppContext.Provider value={value}>{children}</AppContext.Provider>;
}

export function useAppContext() {
  return useContext(AppContext);
}
