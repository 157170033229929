import React from 'react';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import DragIndicatorIcon from '@mui/icons-material/DragIndicator';
import AnnexListItemCard from './AnnexListItemCard';



const AnnexListItem = React.forwardRef(
  (
    {
      // selected,
      hideDragIndicator = false,
      onDownload = () => { },
      onDelete = () => { },
      onUpdate = () => { },
      annex,
      ...props
    },
    ref
  ) => (
    <ListItemButton
      ref={ref}
      disableGutters
      dense
      sx={{ px: 1 }}
      disableRipple
      {...props}
    >
      {!hideDragIndicator && (
        <ListItemIcon sx={{ minWidth: 'auto', mr: 1 }} data-movable-handle>
          <DragIndicatorIcon />
        </ListItemIcon>
      )}
      <ListItemText
        disableTypography
        primary={
          <AnnexListItemCard
            name={annex.name}
            creationTime={annex.creationTime}
            onDelete={onDelete}
            onDownload={onDownload}
            onUpdate={onUpdate}
          />
        }
      />
    </ListItemButton>
  )
);

export default AnnexListItem;
